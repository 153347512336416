<template>
  <div class="mark-account">
    <div class="mark-account-left" v-loading="loading">
      <ul
        id="t-scroll-mark-account"
        v-infinite-scroll="getPage"
        :infinite-scroll-disabled="scrollDisabled"
        :infinite-scroll-distance="0"
      >
        <li
          v-for="(item, i) in computedUserList"
          :key="i"
          @click="clinckAccount(item.userId)"
          :class="selectAccount == item.userId ? 'select-account' : ''"
        >
          <img
            :src="
              item.user_photo
                ? 'data:image/png;base64,' + item.user_photo
                : require('@/assets/imgs/p.png')
            "
            alt=""
          />
          <div>
            <div class="name">
              {{ item.userFirstName }} {{ item.userLasttName }}
            </div>
            <div class="user-name">
              {{ item.userId }}
            </div>
          </div>
          <el-tag
            type="danger"
            v-if="item.noRead"
            class="danger-tag"
            size="mini"
            effect="dark"
          >
            {{ item.noRead }}
          </el-tag>
          <i
            class="el-icon-delete-solid"
            @click.stop="clearChinnile(item.id, item.userId)"
          ></i>
        </li>
        <li v-if="dataLoading" class="li-nomore">加载中...</li>
        <li v-else class="li-nomore">没有更多了...</li>
      </ul>
      <!-- <no-data v-show="userList.length == 0" /> -->
    </div>
    <div class="mark-account-right">
      <userPage v-if="selectAccount" :userId="selectAccount" />
      <no-data v-else />
    </div>
  </div>
</template>

<script>
import userPage from './components/user/UserDetail.vue'
import { userRemark } from '@/api/mark/markAccount'
import { clearRemark } from '@/api/mark/markAccount'
import { scrollTopFun } from '@/plugins/logFunction'
export default {
  name: 'MarkInfoAccount',
  components: {
    userPage
  },
  data() {
    return {
      current: 1,
      limit: 50,
      loading: false,
      dataLoading: true,
      scrollDisabled: false,
      userList: [],
      selectAccount: null,
      accountScrollTop: 0
    }
  },
  computed: {
    noReadObj() {
      return this.$store.state.windowData.noRead
    },
    computedUserList() {
      this.userList.forEach(item => {
        if (item.noRead) {
          delete item.noRead
        }
      })
      let arr = this.userList.map(item => {
        if (
          this.noReadObj &&
          this.noReadObj.userRemarkMap &&
          this.noReadObj.userRemarkMap.objMap
        ) {
          for (let i in this.noReadObj.userRemarkMap.objMap) {
            if (i == item.userId) {
              item.noRead = this.noReadObj.userRemarkMap.objMap[i]
            }
          }
        }
        return item
      })

      return arr
    }
  },
  created() {},
  activated() {
    scrollTopFun('t-scroll-mark-account', 'set', this.accountScrollTop)
  },
  deactivated() {
    this.accountScrollTop = scrollTopFun('t-scroll-mark-account', 'get')
  },
  methods: {
    clinckAccount(i) {
      this.selectAccount = i
    },
    getPage() {
      return new Promise((resolve, reject) => {
        this.loading = true
        this.scrollDisabled = true
        userRemark(this.current, this.limit, { remarkType: 2, state: 1 }).then(
          data => {
            this.loading = false
            if (data.items.length > 0) {
              this.userList = this.userList.concat(data.items)
              this.current++
              this.scrollDisabled = false
              if (data.items.length < this.limit) {
                this.dataLoading = false
              }
            } else {
              this.dataLoading = false
            }
            resolve()
          }
        )
      })
    },
    clearChinnile(id, userId) {
      this.$confirm('取消账号标记？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          clearRemark(id).then(data => {
            this.$message({
              type: 'success',
              message: '已取消该账号标记！'
            })
            if (userId == this.selectAccount) {
              this.selectAccount = null
            }
            this.scrollDisabled = true
            this.userList = []
            this.current = 1
            this.getPage()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.mark-account {
  width: 100%;
  height: calc(100vh - 79px);
  background: #f0f2f5;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  .mark-account-left {
    width: 260px;
    height: 100%;
    overflow: hidden;

    border-radius: 5px;
    background: white;
    padding: 0 5px;
    position: relative;
    box-shadow: 0 1px 3px 0 #d8d9d9;
    ul {
      margin: 0;
      padding: 0;
      height: 100%;
      overflow: auto;
      li {
        display: flex;
        padding: 10px 5px;
        border-bottom: 1px solid #f2f4f8;
        position: relative;
        cursor: pointer;
        // 取消标记
        .el-icon-delete-solid {
          display: none;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 0px;
          &:hover {
            color: red;
          }
        }
        .danger-tag {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 0;
        }
        &:hover {
          background: #d0dffd;
          .el-icon-delete-solid {
            display: block;
          }
          .danger-tag {
            right: 20px;
          }
        }
        img {
          align-self: center;
          width: 29px;
          height: 29px;
          border-radius: 5px;
        }
        > div {
          align-self: center;
          width: calc(100% - 30px);
          margin-left: 10px;
          font-size: 12px;
          font-weight: 400;
          color: #121111;
          > div {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .name {
            margin-bottom: 3px;
          }
        }
      }

      .select-account {
        background: #d0dffd;
      }
      .li-nomore {
        padding: 10px 0;
        margin: 0;
        text-align: center;
        font-size: 12px;
        color: #646161;
        border: none;
        justify-content: center;
      }
    }
  }
  .mark-account-right {
    width: calc(100% - 270px);
    height: 100%;
    overflow: hidden;
    border-radius: 5px;
    background: white;
    position: relative;
    box-shadow: 0 1px 3px 0 #d8d9d9;
  }
}
</style>
